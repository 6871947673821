import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Pagina from '../components/Pagina'
import BackgroundImg from 'gatsby-background-image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import './styles/coleta-e-destinacao-de-residuos.scss'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Icons
import reciclagem from '../images/coleta-e-destinacao-de-residuos/reciclagem.svg'
import garrafa from '../images/coleta-e-destinacao-de-residuos/garrafa.svg'
import metal from '../images/coleta-e-destinacao-de-residuos/metal.svg'
import tronco from '../images/coleta-e-destinacao-de-residuos/tronco.svg'
import bulb from '../images/coleta-e-destinacao-de-residuos/bulb.svg'
import apple from '../images/coleta-e-destinacao-de-residuos/apple.svg'
import rejeitos from '../images/coleta-e-destinacao-de-residuos/rejeitos.svg'
import taca from '../images/coleta-e-destinacao-de-residuos/taca.svg'
import pilha from '../images/coleta-e-destinacao-de-residuos/pilha.svg'
import tratamento from '../images/coleta-e-destinacao-de-residuos/tratamento.svg'
import iphone from '../images/coleta-e-destinacao-de-residuos/iphone.svg'
import vacina from '../images/coleta-e-destinacao-de-residuos/vacina.svg'
import fuel from '../images/coleta-e-destinacao-de-residuos/fuel.svg'
import polygon from '../images/coleta-e-destinacao-de-residuos/polygon.svg'

const Paralax = ({ paralax, paralaxMobile }) => {
  const intl = useIntl()
  const image = getImage(paralax)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(paralaxMobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  return (
    <>
      <div className="paralaxGente">
        <BackgroundImg
          {...bgImage}
          Tag="div"
          className="paralaxImage d-lg-block d-none"
        >
          <div className="container h-100">
            <div className="container h-100 d-flex justify-content-lg-start justify-content-center ">
              <div className=" h-100 d-flex align-items-center">
                <div className="w-100">
                  <h1 className="pb-4 text-white">{parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.parallax.title' }))}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImg>
        <BackgroundImg
          {...bgImageMobile}
          Tag="div"
          className="paralaxImage d-lg-none d-block"
        >
          <div className="container h-100">
            <div className="container h-100 ">
              <div className=" h-100 d-flex align-items-center">
                <div className="w-100">
                  <h1 className="text-white">{parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.parallax.title' }))}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImg>
        <div className="gradient-blue-to-green"></div>
      </div>
    </>
  )
}

const Texto = () => {
  const intl = useIntl()
  return (
    <>
      <section className="full m-0 pt-4 pb-4">
        <div className="container pb-lg-0 px-0">
          <div className="row m-0 px-4">
            <p className='font-Spinnaker'>{parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.texto.p1' }))}
            </p>
            <p className='font-Spinnaker'>{parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.texto.p2' }))}</p>
          </div>
        </div>
      </section>
      {/* <div className="gradient-blue-to-green"></div> */}
    </>
  )
}

const CardsColeta = (data) => {
  const [cardOpened, setcardOpened] = useState('')
  const intl = useIntl()

  return (
    <>
      <div className='container position-relative'>
        <div className='row row-cols-lg-3 row-cols-md-2 row-cols-1'>
          <div>
            <div className='col d-flex justify-content-center' >
              <div className='cardColeta '>
                <img src={reciclagem} ></img>
                <img src={polygon} onClick={() => setcardOpened('reciclagem')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_reciclagem.title' }))}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-0 pt-md-0 pt-4'>
              <div className='cardColeta '>
                <img src={garrafa} ></img>
                <img src={polygon} onClick={() => setcardOpened('plastico')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_plastico.title' }))}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-0 pt-md-5 pt-4'>
              <div className='cardColeta '>
                <img src={metal} ></img>
                <img src={polygon} onClick={() => setcardOpened('metal')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_metal.title' }))}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-5 pt-md-5 pt-4'>
              <div className='cardColeta '>
                <img src={tronco} ></img>
                <img src={polygon} onClick={() => setcardOpened('tronco')} ></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_tronco.title' }))}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-5 pt-md-5 pt-4'>
              <div className='cardColeta '>
                <img src={taca} ></img>
                <img src={polygon} onClick={() => setcardOpened('vidro')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_vidro.title' }))}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-5 pt-md-5 pt-4'>
              <div className='cardColeta '>
                <img src={iphone} ></img>
                <img src={polygon} onClick={() => setcardOpened('celular')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_celular.title' }))}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-5 pt-md-5 pt-4'>
              <div className='cardColeta '>
                <img src={bulb} ></img>
                <img src={polygon} onClick={() => setcardOpened('bulb')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_bulb.title' }))}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-5 pt-md-5 pt-4'>
              <div className='cardColeta '>
                <img src={pilha} ></img>
                <img src={polygon} onClick={() => setcardOpened('pilha')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_pilha.title' }))}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-5 pt-md-5 pt-4'>
              <div className='cardColeta '>
                <img src={vacina} ></img>
                <img src={polygon} onClick={() => setcardOpened('vacina')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_vacina.title' }))}

                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-5 pt-md-5 pt-4'>
              <div className='cardColeta '>
                <img src={apple} ></img>
                <img src={polygon} onClick={() => setcardOpened('apple')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_apple.title' }))}

                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-5 pt-md-5 pt-4'>
              <div className='cardColeta '>
                <img src={tratamento} ></img>
                <img src={polygon} onClick={() => setcardOpened('tratamento')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_tratamento.title' }))}

                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-5 pt-md-5 pt-4'>
              <div className='cardColeta '>
                <img src={fuel} ></img>
                <img src={polygon} onClick={() => setcardOpened('fuel')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_fuel.title' }))}

                </p>
              </div>
            </div>
          </div>
          <div>
            <div className='col d-flex justify-content-center pt-lg-5 pb-lg-5 pt-md-5 pt-4 pb-4'>
              <div className='cardColeta '>
                <img src={rejeitos} ></img>
                <img src={polygon} onClick={() => setcardOpened('rejeitos')}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_rejeitos.title' }))}

                </p>
              </div>
            </div>
          </div>
        </div>
        {cardOpened === 'reciclagem'
          ? <div className='cardPosition'>
            <div className='cardOpenedBox row container p-0'>
              <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                <img src={reciclagem} ></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_reciclagem.title' }))}

                </p>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_reciclagem.p' }))}

                </p>
              </div>
              <div className='col-lg-6 imagem d-lg-block d-none'>
                <GatsbyImage
                  image={data.reciclagem} />
              </div>
              <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
            </div>
          </div>
          : <div className='d-none'></div>
        }
        {cardOpened === 'plastico'
          ? <div className='cardPosition'>
            <div className='cardOpenedBox row container p-0'>
              <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                <img src={garrafa} ></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_plastico.title' }))}

                </p>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_plastico.p' }))}

                </p>
              </div>
              <div className='col-lg-6 imagem d-lg-block d-none'>
                <GatsbyImage
                  image={data.plastico} />
              </div>
              <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
            </div>
          </div>
          : <div className='d-none'></div>
        }
        {cardOpened === 'metal'
          ? <div className='cardPosition'>
            <div className='cardOpenedBox row container p-0'>
              <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                <img src={metal} ></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_metal.title' }))}

                </p>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_metal.p' }))}

                </p>
              </div>
              <div className='col-lg-6 imagem d-lg-block d-none'>
                <GatsbyImage
                  image={data.metal} />
              </div>
              <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
            </div>
          </div>
          : <div className='d-none'></div>
        }
        {cardOpened === 'tronco'
          ? <div className='cardPosition topCustomMobileRow1'>
            <div className='cardOpenedBox row container p-0'>
              <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                <img src={tronco} ></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_tronco.title' }))}

                </p>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_tronco.p' }))}

                </p>
              </div>
              <div className='col-lg-6 imagem d-lg-block d-none'>
                <GatsbyImage
                  image={data.madeira} />
              </div>
              <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
            </div>
          </div>
          : <div className='d-none'></div>
        }
        {cardOpened === 'vidro'
          ? <div className='cardPosition topCustomMobileRow1'>
            <div className='cardOpenedBox row container p-0'>
              <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                <img src={taca} ></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_vidro.title' }))}

                </p>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_vidro.p' }))}

                </p>
              </div>
              <div className='col-lg-6 imagem d-lg-block d-none'>
                <GatsbyImage
                  image={data.vidro} />
              </div>
              <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
            </div>
          </div>
          : <div className='d-none'></div>
        }
        {cardOpened === 'celular'
          ? <div className='cardPosition topCustomMobileRow1'>
            <div className='cardOpenedBox row container p-0'>
              <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                <img src={iphone} ></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_celular.title' }))}
                </p>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_celular.p' }))}
                </p>
              </div>
              <div className='col-lg-6 imagem d-lg-block d-none'>
                <GatsbyImage
                  image={data.celular} />
              </div>
              <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
            </div>
          </div>
          : <div className='d-none'></div>
        }
        {cardOpened === 'bulb'
          ? <div className='cardPosition topCustomMobileRow2'>
            <div className='cardOpenedBox row container p-0'>
              <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                <img src={bulb} ></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_bulb.title' }))}
                </p>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_bulb.p' }))}
                </p>
              </div>
              <div className='col-lg-6 imagem d-lg-block d-none'>
                <GatsbyImage
                  image={data.bulb} />
              </div>
              <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
            </div>
          </div>
          : <div className='d-none'></div>
        }
        {cardOpened === 'pilha'
          ? <div className='cardPosition topCustomMobileRow2'>
            <div className='cardOpenedBox row container p-0'>
              <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                <img src={pilha} ></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_pilha.title' }))}
                </p>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_pilha.p' }))}
                </p>
              </div>
              <div className='col-lg-6 imagem d-lg-block d-none'>
                <GatsbyImage
                  image={data.pilha} />
              </div>
              <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
            </div>
          </div>
          : <div className='d-none'></div>
        }
        {cardOpened === 'vacina'
          ? <div className='cardPosition topCustomMobileRow2'>
            <div className='cardOpenedBox row container p-0'>
              <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                <img src={vacina} ></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_vacina.title' }))}
                </p>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_vacina.p' }))}
                </p>
              </div>
              <div className='col-lg-6 imagem d-lg-block d-none'>
                <GatsbyImage
                  image={data.vacina} />
              </div>
              <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
            </div>
          </div>
          : <div className='d-none'></div>
        }
        {cardOpened === 'apple'
          ? <><div className='cardPosition topCustomMobileRow2'>
            <div className='cardOpenedBox row container p-0'>
              <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                <img src={vacina}></img>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_apple.title' }))}
                </p>
                <p className='font-Spinnaker'>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_apple.p.p1' }))}
                  <span className='font-Spinnaker text-lwart-blue2'>{parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_apple.p.span1' }))}
                  </span>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_apple.p.p2' }))}
                  <span className='font-Spinnaker text-lwart-blue2'>{parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_apple.p.span2' }))}
                  </span>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_apple.p.p3' }))}
                  <span className='font-Spinnaker text-lwart-blue2'>{parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_apple.p.span3' }))}
                  </span>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_apple.p.p4' }))}
                </p>
              </div>
              <div className='col-lg-6 imagem d-lg-block d-none'>
                <GatsbyImage
                  image={data.apple} />
              </div>
              <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
            </div>
          </div><div className='d-md-none d-block divBottom'></div></>
          : <div className='d-none'></div>
        }
        {cardOpened === 'tratamento'
          ? <>
            <div className='cardPosition topCustomMobileRow3'>
              <div className='cardOpenedBox row container p-0'>
                <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                  <img src={tratamento}></img>
                  <p className='font-Spinnaker'>
                    {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_tratamento.title' }))}
                  </p>
                  <p className='font-Spinnaker'>
                    {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_tratamento.p.p1' }))}
                    <span className='font-Spinnaker text-lwart-blue2'>{parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_tratamento.p.span1' }))}</span><br />
                    {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_tratamento.p.p2' }))}
                  </p>
                </div>
                <div className='col-lg-6 imagem d-lg-block d-none'>
                  <GatsbyImage
                    image={data.tratamento} />
                </div>
                <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
              </div>
            </div>
            <div className='d-md-none d-block divBottom'></div>
          </>

          : <div className='d-none'></div>
        }
        {cardOpened === 'fuel'
          ? <>
            <div className='cardPosition topCustomMobileRow3'>
              <div className='cardOpenedBox row container p-0'>
                <div className='cardOpened col-lg-6 col-12 pl-lg-0 pr-lg-0'>
                  <img src={fuel}></img>
                  <p className='font-Spinnaker'>
                    {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_fuel.title' }))}
                  </p>
                  <p className='font-Spinnaker'>
                    {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_fuel.p.p1' }))}
                    <span className='font-Spinnaker text-lwart-blue2'>{parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_fuel.p.span1' }))}</span><br />
                    {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_fuel.p.p2' }))}
                  </p>
                </div>
                <div className='col-lg-6 imagem d-lg-block d-none'>
                  <GatsbyImage
                    image={data.fuel} />
                </div>
                <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
              </div>
            </div><div className='d-md-none d-block divBottom'></div>
          </>

          : <div className='d-none'></div>
        }
        {cardOpened === 'rejeitos'
          ? <>
            <div className='cardPosition topCustomMobileRow3'>
              <div className='cardOpenedBox row container p-0'>
                <div className='cardOpened col-lg col-12 pl-lg-0 pr-lg-0'>
                  <img src={rejeitos}></img>
                  <p className='font-Spinnaker'>
                    {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_rejeitos.title' }))}
                  </p>
                  <p className='font-Spinnaker'>
                    {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_rejeitos.p.p1' }))}
                    <span className='font-Spinnaker text-lwart-blue2'>{parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_rejeitos.p.span1' }))}
                    </span><br />
                    {parse(intl.formatMessage({ id: 'paginas.coleta_e_destinacao_de_residuos.cards_coleta.card_rejeitos.p.p2' }))}
                  </p>
                </div>
                <div className='col-lg-6 imagem d-lg-block d-none'>
                  <GatsbyImage
                    image={data.rejeitos} />
                </div>
                <img src={polygon} className='iconClose' onClick={() => setcardOpened('')}></img>
              </div>
            </div><div className='d-md-none d-block divBottomLast'></div>
          </>
          : <div className='d-none'></div>
        }
      </div>
    </>
  )
}

const ColetaDestinacaoTotalResiduo = ({ data }) => {
  return <>
    <Pagina pagina={data.pagina} />
    <Paralax paralax={data.paralax.childImageSharp.gatsbyImageData} paralaxMobile={data.paralaxMobile.childImageSharp.gatsbyImageData} />
    <Texto />
    <CardsColeta
      reciclagem={data.reciclagem.childImageSharp.gatsbyImageData}
      plastico={data.plastico.childImageSharp.gatsbyImageData}
      metal={data.metal.childImageSharp.gatsbyImageData}
      madeira={data.madeira.childImageSharp.gatsbyImageData}
      vidro={data.vidro.childImageSharp.gatsbyImageData}
      celular={data.celular.childImageSharp.gatsbyImageData}
      bulb={data.bulb.childImageSharp.gatsbyImageData}
      pilha={data.pilha.childImageSharp.gatsbyImageData}
      vacina={data.vacina.childImageSharp.gatsbyImageData}
      apple={data.apple.childImageSharp.gatsbyImageData}
      tratamento={data.tratamento.childImageSharp.gatsbyImageData}
      fuel={data.fuel.childImageSharp.gatsbyImageData}
      rejeitos={data.rejeitos.childImageSharp.gatsbyImageData} />
  </>
}

export default ColetaDestinacaoTotalResiduo

export const query = graphql`{
    pagina: contentfulPagina(path: {eq: "/coleta-e-destinacao-de-residuos/"}) {
      path
      metaTitle
      metaDescription
      pageTitle
      tags
      ogImage {
        localFile {
          publicURL
        }
      }
    }
    paralax: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/coleta-e-destinacao-de-residuos.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    paralaxMobile: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/coleta-destinacao.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    reciclagem: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-10.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    plastico: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-11.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    metal: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-12.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    madeira: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-13.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    vidro: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-14.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    celular: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-15.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    bulb: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-16.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    pilha: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-17.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    vacina: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-18.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    apple: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-19.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    tratamento: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-20.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    fuel: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-21.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    rejeitos: file(relativePath: {eq: "coleta-e-destinacao-de-residuos/image-22.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`
